import {
  IEmission,
  IEmissionType,
  IEmissionTypeNode,
  IOrganisationUnit,
  IOrgUnitNode,
} from "../models/models";

export default class Helper {
  public static round(num: number): number {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
  }

  public static filterEmissions(
    emissions: IEmission[],
    startMonth: number,
    endMonth: number,
    orgUnit: IOrganisationUnit,
    emissionType: IEmissionType
  ) {
    if (!emissions || !emissions.length) {
      console.error("emissions is null, undefined or contains no items.");
      return;
    }

    let result = emissions;

    if (startMonth > 1 || endMonth < 12) {
      result = result.filter(
        (x) => x.Month >= startMonth && x.Month <= endMonth
      );
    }

    if (orgUnit) {
      const orgUnitIds = Helper.getAllOrgUnitChildren(orgUnit);
      result = result.filter(function(e) {
        return this.indexOf(e.OrgUnitId) > -1;
      }, orgUnitIds);
    }

    if (emissionType) {
      const emissionTypeIds = Helper.getAllEmissionTypeChildren(emissionType);
      result = result.filter(function(e) {
        return this.indexOf(e.EmissionTypeId) > -1;
      }, emissionTypeIds);
    }

    return result;
  }

  public static getAllOrgUnitChildren(orgUnit: IOrganisationUnit) {
    var children = [orgUnit.Id];

    for (var i in orgUnit.Children) {
      children = children.concat(
        this.getAllOrgUnitChildren(orgUnit.Children[i])
      );
    }

    return children;
  }

  public static getAllEmissionTypeChildren(emissionType: IEmissionType) {
    var children = [emissionType.Id];

    for (var i in emissionType.Children) {
      children = children.concat(
        this.getAllEmissionTypeChildren(emissionType.Children[i])
      );
    }

    return children;
  }

  public static createOrgUnitTreeRecursive(
    orgUnits: IOrganisationUnit[]
  ): IOrgUnitNode[] {
    var nodes = Array<IOrgUnitNode>();

    for (var i in orgUnits) {
      //const orgUnitEmissions = emissions.filter(x => x.OrganisationUnitId == orgUnits[i].Id) ?? [];

      const node = {} as IOrgUnitNode;
      node.Id = orgUnits[i].Id;
      node.Name = orgUnits[i].Name;
      node.CostCenter = orgUnits[i].CostCenter;
      node.Children = this.createOrgUnitTreeRecursive(orgUnits[i].Children);
      // node.Co2 = orgUnitEmissions.reduce((a, b) => a + b.Co2, 0),
      // node.TotalCo2 = node.Co2 + node.Children.reduce((a, b) => a + b.TotalCo2, 0);
      // node.Cost = orgUnitEmissions.reduce((a, b) => a + b.Cost, 0);
      // node.TotalCost = node.Cost + node.Children.reduce((a, b) => a + b.TotalCost, 0);
      // node.Emissions = orgUnitEmissions;
      nodes.push(node);
    }

    return nodes;
  }

  public static updateOrgUnitTreeRecursive(
    orgUnits: IOrgUnitNode[],
    emissions: IEmission[]
  ) {
    if (!emissions || !emissions.length) {
      console.error("emissions is null, undefined or contains no items.");
      return;
    }

    for (var i in orgUnits) {
      const orgUnitEmissions =
        emissions.filter((x) => x.OrgUnitId == orgUnits[i].Id) ?? [];

      const node = orgUnits[i];
      this.updateOrgUnitTreeRecursive(node.Children, emissions);

      node.Co2 = orgUnitEmissions.reduce((a, b) => a + b.Co2, 0);
      node.TotalCo2 =
        node.Co2 + node.Children.reduce((a, b) => a + b.TotalCo2, 0);
      node.Cost = orgUnitEmissions.reduce((a, b) => a + b.Cost, 0);
      node.TotalCost =
        node.Cost + node.Children.reduce((a, b) => a + b.TotalCost, 0);
      node.Emissions = orgUnitEmissions;
    }
  }

  public static createEmissionTypeTreeRecursive(
    emissionTypes: IEmissionType[],
    emissions: IEmission[]
  ) {
    if (!emissions || !emissions.length) {
      console.error("emissions is null, undefined or contains no items.");
      return;
    }

    var nodes = Array<IEmissionTypeNode>();

    for (var i in emissionTypes) {
      //const emissionTypeEmissions = emissions.filter(x => x.EmissionTypeId == emissionTypes[i].Id) ?? [];

      const node = {} as IEmissionTypeNode;
      node.Id = emissionTypes[i].Id;
      node.Name = emissionTypes[i].Name;
      node.Children = this.createEmissionTypeTreeRecursive(
        emissionTypes[i].Children,
        emissions
      );
      // node.Co2 = emissionTypeEmissions.reduce((a, b) => a + b.Co2, 0),
      // node.TotalCo2 = node.Co2 + node.Children.reduce((a, b) => a + b.TotalCo2, 0);
      // node.Cost = emissionTypeEmissions.reduce((a, b) => a + b.Cost, 0);
      // node.TotalCost = node.Cost + node.Children.reduce((a, b) => a + b.TotalCost, 0);
      nodes.push(node);
    }

    return nodes;
  }

  public static updateEmissionTypeTreeRecursive(
    emissionTypes: IEmissionTypeNode[],
    emissions: IEmission[]
  ) {
    if (!emissions || !emissions.length) {
      console.error("emissions is null, undefined or contains no items.");
      return;
    }

    for (var i in emissionTypes) {
      const emissionTypeEmissions =
        emissions.filter((x) => x.EmissionTypeId == emissionTypes[i].Id) ?? [];

      const node = emissionTypes[i];
      this.updateEmissionTypeTreeRecursive(node.Children, emissions);

      node.Co2 = emissionTypeEmissions.reduce((a, b) => a + b.Co2, 0);
      node.TotalCo2 =
        node.Co2 + node.Children.reduce((a, b) => a + b.TotalCo2, 0);
      node.Cost = emissionTypeEmissions.reduce((a, b) => a + b.Cost, 0);
      node.TotalCost =
        node.Cost + node.Children.reduce((a, b) => a + b.TotalCost, 0);
      node.Quantity = emissionTypeEmissions.reduce((a, b) => a + b.Quantity, 0);
      node.TotalQuantity =
        node.Quantity + node.Children.reduce((a, b) => a + b.TotalQuantity, 0);
    }
  }

  public static getEmissionTypeById(
    id: number,
    emissionTypes: IEmissionType[]
  ) {
    for (var index in emissionTypes) {
      if (emissionTypes[index].Id === id) {
        return emissionTypes[index];
      } else if (emissionTypes[index].Children) {
        let activity = this.getEmissionTypeById(
          id,
          emissionTypes[index].Children
        );
        if (activity) return activity;
      }
    }

    return null;
  }

  public static getEmissionTypeById2(
    id: number,
    emissionTypes: IEmissionTypeNode[]
  ) {
    for (var index in emissionTypes) {
      if (emissionTypes[index].Id === id) {
        return emissionTypes[index];
      } else if (emissionTypes[index].Children) {
        let activity = this.getEmissionTypeById2(
          id,
          emissionTypes[index].Children
        );
        if (activity) return activity;
      }
    }

    return null;
  }

  public static groupBy<T, K>(list: T[], getKey: (item: T) => K) {
    const map = new Map<K, T[]>();
    list.forEach((item) => {
      const key = getKey(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return Array.from(map.values());
  }

  public static range(size: number, startAt: number = 0) {
    return [...Array(size).keys()].map((i) => i + startAt);
  }

  public static removeTrailingZeros(list: number[]): number[] {
    while (list[list.length - 1] === 0) {
      list.pop();
    }

    return list;
  }
}
