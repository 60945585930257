import Vue from 'vue';
import numeral from 'numeral'

// load a locale
numeral.register('locale', 'sv', {
        delimiters: {
          thousands: ' ',
          decimal: ','
        },
        abbreviations: {
          thousand: 'k',
          million: 'm',
          billion: 'b',
          trillion: 't'
        },
        ordinal: function (number) {
          return '.';
        },
        currency: {
          symbol: 'kr'
        },
    });

// switch between locales
numeral.locale('sv');
numeral.zeroFormat('0');

Vue.filter('number', (val, format) => {
  return numeral(val).format(format)
});

