export interface Color {
  name: string;
  id: number;
  color: string;
}

export const findByName = (name: string) => {
  return colors.find((item) => item.name === name);
};

export const colors: Color[] = [
  {
    name: "Alkylatbensin",
    id: 26,
    color: "#F44336",
  },
  {
    name: "Bensin",
    id: 13,
    color: "#E91E63",
  },
  {
    name: "Biodiesel",
    id: 11,
    color: "#9C27B0",
  },
  {
    name: "Biogas",
    id: 17,
    color: "#673AB7",
  },
  {
    name: "Diesel",
    id: 7,
    color: "#3F51B5",
  },
  {
    name: "E85",
    id: 14,
    color: "#2196F3",
  },
  {
    name: "El miljömärkt",
    id: 5,
    color: "#03A9F4",
  },
  {
    name: "El residual",
    id: 6,
    color: "#00BCD4",
  },
  // {
  //   name: "Fjärrkyla",
  //   id: null,
  //   color: "#009688",
  // },
  {
    name: "Fjärrvärme",
    id: 21,
    color: "#4CAF50",
  },
  {
    name: "Fordonsgas",
    id: 8,
    color: "#8BC34A",
  },
  {
    name: "Gasol",
    id: 20,
    color: "#CDDC39",
  },
  {
    name: "HVO100",
    id: 12,
    color: "#FFEB3B",
  },
  // {
  //   name: "Naturgas",
  //   id: 16,
  //   color: "#FFC107",
  // },
  // {
  //   name: "Eldn olja 1",
  //   id: 19,
  //   color: "#BC8F8F",
  // },
  // {
  //   name: "Eldn olja 2-5",
  //   id: 18,
  //   color: "#8B4513",
  // },
];
