
















import { Vue, Component, Prop } from "vue-property-decorator";

import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import defaultChartOptions from "@/models/defaultChartOptions";
import { organisationModule } from "@/store/modules/organisation";
import { IEmission } from "@/models/models";
import Helper from "@/utils/helper";
import { ApexOptions } from "apexcharts";
import _ from "lodash";

@Component({
  components: {
    apexchart: VueApexCharts,
  },
})
export default class ColumnChart extends Vue {
  @Prop({ default: "450" }) readonly chartHeight!: string;

  public get organisation() {
    return organisationModule;
  }

  get options() {
    const startMonthName = moment()
      .month(this.organisation.startMonth - 1)
      .format("MMM");
    const endMonthName = moment()
      .month(this.organisation.endMonth - 1)
      .format("MMM");

    const datePeriod =
      startMonthName == endMonthName
        ? startMonthName
        : `${startMonthName} - ${endMonthName}`;

    const options: ApexOptions = {
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: ["#FFE499", "#fac228", "#a8dadc", "#146e71"],
      xaxis: {
        categories: moment
          .monthsShort()
          .map((m) => this.$options.filters.capitalize(m)),
        labels: {
          rotate: -45,
          trim: false,
        },
      },
      yaxis: {
        title: {
          text: "CO\u2082 (kg)",
        },
        labels: {
          formatter: (val) => {
            return Math.round(val).toString();
          },
        },
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return this.$options.filters.number(val) + " kg CO<sub>2</sub>";
          },
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      legend: {
        showForZeroSeries: false,
        position: "bottom",
        horizontalAlign: "center",
        offsetY: 8,
        show: true,
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: this.organisation.orgUnit.Name + " koldioxidutsläpp",
      },
      subtitle: {
        text: `${this.organisation.emissionType.Name}, ${datePeriod} ${this.organisation.year}`,
      },
    };

    return _.merge({}, defaultChartOptions, options);
  }

  get series() {
    const series = [
      ...(this.organisation.comparsionYear
        ? [
            {
              name: this.organisation.comparsionYear,
              data: this.groupByMonth(
                this.organisation.getFilteredEmissionsForComparsionYear ?? []
              ),
            },
          ]
        : []),
      {
        name: this.organisation.year,
        data: this.groupByMonth(this.organisation.getFilteredEmissions ?? []),
      },
    ];

    return series;
  }

  groupByMonth(emissions: IEmission[]) {
    var result = [];
    emissions.reduce(function(res, value) {
      if (!res[value.Month]) {
        res[value.Month] = { Month: value.Month, Co2: 0 };
        result.push(res[value.Month]);
      }
      res[value.Month].Co2 += value.Co2;
      return res;
    }, {});

    return Array.from(Helper.range(12, 1), (i) => {
      const month = result.find((x) => x.Month == i);
      if (month) return Helper.round(month.Co2);
      return 0;
    });

    //return result.map(x => Math.round(x.Co2));
  }
}
