












































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { organisationModule } from "@/store/modules/organisation";
import TreeMenu from "./TreeMenu.vue";
import Dropdown from "./Dropdown.vue";
import moment from "moment";

@Component({
  components: {
    TreeMenu,
    Dropdown
  }
})
export default class FilterBar extends Vue {
  private openTreeMenu: any = { organizations: false, activities: false };

  @Prop()
  public mode: FilterBarMode; // = FilterBarMode.CurrentYearOnly;

  get organisation() {
    return organisationModule;
  }

  get showOrganisation() {
    return (
      this.mode == FilterBarMode.CurrentYearDetailed ||
      this.mode == FilterBarMode.Everything
    );
  }

  get showMonths() {
    return (
      this.mode == FilterBarMode.CurrentYearDetailed ||
      this.mode == FilterBarMode.Everything
    );
  }

  get showComparsionYear() {
    return this.mode == FilterBarMode.Everything;
  }

  get showEmissionTypes() {
    return this.mode == FilterBarMode.Everything;
  }

  getMonthShortName(month: number) {
    return moment(month, "M").format("MMM");
  }

  selectOrgUnit(orgUnit) {
    this.openTreeMenu.organizations = false;
    this.organisation.setOrgUnit(orgUnit);
  }

  selectEmissionType(activity) {
    this.openTreeMenu.activities = false;
    this.organisation.setEmissionType(activity);
  }

  selectYear(year: string) {
    this.organisation.setYear(parseInt(year));
  }

  selectComparsionYear(year: string) {
    this.organisation.setComparsionYear(parseInt(year));
  }

  selectStartMonth(month: number) {
    this.organisation.setStartMonth(month);
  }

  selectEndMonth(month: number) {
    this.organisation.setEndMonth(month);
  }
}

export enum FilterBarMode {
  CurrentYearOnly,
  CurrentYearDetailed,
  Everything
}
