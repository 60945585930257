















import { Vue, Component, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import defaultChartOptions from "@/models/defaultChartOptions";
import { organisationModule } from "@/store/modules/organisation";
import _ from "lodash";

@Component({
  components: {
    apexchart: VueApexCharts,
  },
})
export default class StackedBarChart extends Vue {
  @Prop({ default: "450" }) readonly chartHeight!: string;
  @Prop({ default: "Utsläpp per organisatorisk enhet" })
  readonly chartTitle!: string;
  @Prop({ default: true }) readonly chartSubTitleActive!: boolean;
  @Prop({ default: "left" }) readonly chartTitleAlign!: string;
  @Prop({ default: true }) readonly shouldGetStatic!: boolean;
  @Prop({ default: "" }) readonly borderSettings!: string;

  public get organisation() {
    return organisationModule;
  }

  get options() {
    const options = {
      chart: {
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        y: {
          formatter: function(val: number, opts: any) {
            const sum = opts.series[0].reduce((a, b) => a + b, 0);
            const percent = (val / sum) * 100;
            return percent.toFixed(1) + "%";
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function(val: number, opt: any) {
          const goals = opt.w.config.series[opt.seriesIndex].data.reduce(
            (a, b) => a + b,
            0
          );

          const percent = (val / goals) * 100;
          return percent.toFixed(1) + "%";
        },
      },
      xaxis: {
        categories: this.getData.categories,
      },
      title: {
        align: this.chartTitleAlign,
        text: this.chartTitle,
      },
      subtitle: {
        text: this.chartSubTitleActive
          ? "Under " +
            this.organisation.orgUnit.Name +
            " - " +
            this.organisation.year
          : "",
      },
      colors: [
        "#1c98a2",
        "#f39200",
        "#fac228",
        "#146e71",
        "#FFE499",
        "#a8dadc",
        "#152b71",
      ],
    };

    return _.merge({}, defaultChartOptions, options);
  }

  get getData() {
    return this.organisation.getStaticCo2PerOrgUnitForCurrentOrgUnitAndPeriod;
  }

  get series() {
    // Every data point has 5 values, each value should
    // be attached to a single category
    // Cat[i] -> Data[z][i]

    const data = this.getData;

    var categories = [];
    data.categories.map((category) => {
      var newObject = { categoryName: category, collectedData: [] };
      return categories.push(newObject);
    });

    const series = data.data.length > 0 ? data.data : [];

    var totalAmountOfData = 0;
    // Add series data to their attached categories
    series.forEach((dataPoint) => {
      // Check the total amount (To calculate the percentage later)
      var sum = dataPoint.data.reduce((a: any, b: any) => {
        return a + b;
      }, 0);
      totalAmountOfData = totalAmountOfData + sum;

      // Every dataPoint has 5 values that are supposed
      // to be attached to each category
      // We divide them based on the index
      dataPoint.data.forEach((categoryData: [], index: number) => {
        categories[index].collectedData.push(categoryData);
      });
    });

    var collectedSeries = [{ data: [] }];
    // Sum all data collected
    categories.forEach((category, index) => {
      var sum = category.collectedData.reduce((a: any, b: any) => {
        return a + b;
      }, 0);
      categories[index].collectedData = sum;
      var newSeriesObj = { name: "", data: [sum] };
      collectedSeries[0].data.push((sum / totalAmountOfData) * 100);
    });
    return collectedSeries;
  }
}
