import Vue from 'vue';

Vue.filter('unit', (val) => {
	if (val == null) {
		return ''
	} else if (val == 'm3') {
		return 'm<sup>3</sup>'
	}
	return val
});
