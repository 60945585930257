
























































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { organisationModule } from "@/store/modules/organisation";
import api from "../services/api";
import VueApexCharts from "vue-apexcharts";
import defaultChartOptions from "@/models/defaultChartOptions";
import Utils from "@/common/utils/utils";
import Dropdown from "@/components/shared/Dropdown.vue";
import TreeMenu from "@/components/shared/TreeMenu.vue";
import FoodIcon from "vue-material-design-icons/Food.vue";
import CarIcon from "vue-material-design-icons/Car.vue";
import TrainIcon from "vue-material-design-icons/Train.vue";
import AirplaneIcon from "vue-material-design-icons/Airplane.vue";
import HumanGreetingIcon from "vue-material-design-icons/HumanGreeting.vue";
import InfoIcon from "vue-material-design-icons/InformationOutline.vue";
import Helper from "../utils/helper";
import _ from "lodash";

// Components
import Button from "../components/shared/Button.vue";

// Charts
import YearlyChart from "../components/overview/YearlyChart.vue";
import { ApexOptions } from "apexcharts";
import {
  ChartSubTitle,
  EnergyDataUnit,
} from "@/components/energy/energyDataEnums";

@Component({
  components: {
    apexchart: VueApexCharts,
    TreeMenu,
    Dropdown,
    FoodIcon,
    CarIcon,
    TrainIcon,
    AirplaneIcon,
    HumanGreetingIcon,
    InfoIcon,
    Button,
    YearlyChart,
  },
})
export default class GeoData extends Vue {
  public data: any = {};
  public isDataLoaded: boolean = false;

  public emissionChartType: ApexChart["type"] = "area";
  public grpChartType: ApexChart["type"] = "line";
  public latestYearlyMwhYear: string = "N/A";
  public showPerCapita: boolean = false;
  public showPerCapitaGrp: boolean = false;
  public selectedSector: any = null;
  public sectors: any = null;
  public isSectorDropdownOpen: boolean = false;

  get organisation() {
    return organisationModule;
  }

  get endUsageEnergyPieChartOptions() {
    return _.merge({}, defaultChartOptions, this.boughtEnergyPieChartOptions, {
      labels: [ChartSubTitle.RENEWABLE, ChartSubTitle.FOSSIL, "Kärnkraft"],
      colors: ["#f39200", "#1c98a2", "#70BA40"],
    } as ApexOptions);
  }

  public get endUsageEnergyPieChartSeries() {
    return [396534, 245394, 102327];
  }

  get boughtEnergyPieChartOptions() {
    const options: ApexOptions = {
      chart: {
        toolbar: {
          show: false,
        },
        events: {
          dataPointMouseEnter: function(event, chartContext, config) {
            document.body.style.cursor = "pointer";
          },
          dataPointMouseLeave: function(event, chartContext, config) {
            document.body.style.removeProperty("cursor");
          },
        },
      },
      stroke: {
        show: false,
      },
      plotOptions: {
        pie: {
          customScale: 1,
          offsetX: -10,
          donut: {
            size: "60%",
          },
        },
      },
      legend: {
        floating: false,
        formatter: function(legendName, opts) {
          return `${legendName} ${Utils.round(
            opts.w.globals.seriesPercent[opts.seriesIndex]
          )} %`;
        },
        offsetX: -40,
        itemMargin: {
          horizontal: 20,
          vertical: 5,
        },
        fontSize: "16px",
      },
      dataLabels: {
        enabled: false,
        dropShadow: {
          enabled: false,
        },
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return `${Utils.localeFormatter(value)} ${
              EnergyDataUnit.MEGAWATT_HOURS
            }`;
          },
        },
      },
      labels: [ChartSubTitle.RENEWABLE, ChartSubTitle.FOSSIL],
      colors: ["#f39200", "#1c98a2"],
    };

    return _.merge({}, defaultChartOptions, options);
  }

  public get boughtEnergyPieChartSeries() {
    const years = [...this.organisation.yearlyMwh.keys()].sort(function(a, b) {
      return b - a;
    });

    const yearToBeShown =
      years[0] === new Date().getFullYear() ? years[1] : years[0];
    this.latestYearlyMwhYear = yearToBeShown.toString();

    const latestYearlyMwh = this.organisation.yearlyMwh.get(yearToBeShown);

    return latestYearlyMwh
      ? [latestYearlyMwh.renewable, latestYearlyMwh.fossil]
      : [0, 0];
  }

  public async mounted() {
    await this.getGeoEmissions();
  }

  public get showComparsion(): boolean {
    if (this.showPerCapita && this.getSelectedSectors().length == 1)
      return true;
    return false;
  }

  public get geoEmissionsOptions() {
    const options: ApexOptions = {
      chart: {
        type: this.emissionChartType,
        stacked: !this.showComparsion,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: '<i class="fa fa-download"></i>',
            selection: false,
            zoom: false,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: false,
          },
        },
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
        },
      },
      stroke: {
        width: [2, 2],
        dashArray: this.showComparsion ? [0, 8] : [0],
      },
      fill: {
        type: "solid",
      },
      labels: this.emissionLabels,
      dataLabels: {
        enabled: false,
      },
      legend: {
        floating: false,
        horizontalAlign: "center",
        position: "bottom",
        show: true,
      },
      tooltip: {
        followCursor: false,
        y: {
          formatter: (value) => {
            return value + " ton CO<sub>2</sub>";
          },
        },
      },
      yaxis: {
        decimalsInFloat: this.showPerCapita ? 2 : 0,
        forceNiceScale: false,
        title: {
          text: "CO\u2082 (ton)",
        },
      },
      subtitle: {
        text: "",
      },
    };

    return _.merge({}, defaultChartOptions, options);
  }

  private async getGeoEmissions() {
    var data = await api.getGeoEmissions(this.organisation.areaId);

    // Add missing years
    for (const i in data.emissions) {
      const { co2, co2PC, co2TPC, sectorId, year } = data.emissions[i];
      if (year == 1990) {
        for (let missingYear = 1991; missingYear <= 1999; missingYear++) {
          data.emissions.push({
            year: missingYear,
            co2,
            co2PC,
            co2TPC,
            sectorId,
          });
        }
      } else if (year == 2000) {
        for (let missingYear = 2001; missingYear <= 2004; missingYear++) {
          data.emissions.push({
            year: missingYear,
            co2,
            co2PC,
            co2TPC,
            sectorId,
          });
        }
      } else if (year == 2005) {
        for (let missingYear = 2006; missingYear <= 2009; missingYear++) {
          data.emissions.push({
            year: missingYear,
            co2,
            co2PC,
            co2TPC,
            sectorId,
          });
        }
      }
    }

    data.emissions.sort((a, b) => a.year - b.year);
    this.data = data;

    // TODO: percapita
    this.sectors = this.getSectorTree();

    this.isDataLoaded = true;
  }

  public get parentSectors(): any[] {
    return this.data.sectors.filter(
      (c, i, arr) => arr.findIndex((t) => t.category === c.category) === i
    );
  }

  public getSelectedSectors() {
    var sectorsToShow = this.sectors;

    if (this.selectedSector) {
      sectorsToShow =
        this.selectedSector.children && this.selectedSector.children.length > 0
          ? this.selectedSector.children
          : [this.selectedSector];
    }

    return sectorsToShow;
  }

  public get grpOptions() {
    const options: ApexOptions = {
      chart: {
        type: this.grpChartType,
        stacked: false,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: '<i class="fa fa-download"></i>',
            selection: false,
            zoom: false,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: false,
          },
        },
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
        },
      },
      stroke: {
        width: [2, 2],
        dashArray: this.grpChartType == "line" ? [0, 8] : [0],
      },
      fill: {
        type: "solid",
      },
      labels: this.grpLabels,
      yaxis: [
        {
          decimalsInFloat: this.showPerCapitaGrp ? 2 : 0,
          forceNiceScale: false,
          opposite: true,
          title: {
            text: "BRP (tusen kronor)",
          },
        },
        {
          decimalsInFloat: 0,
          forceNiceScale: false,
          title: {
            text: "CO\u2082 (ton)",
          },
        },
      ],
      subtitle: {
        text: "",
      },
    };

    return _.merge({}, defaultChartOptions, options);
  }

  public get grpSeries() {
    var series = [];

    // Grp
    series.push({
      data: this.data.grps.map((x) =>
        this.showPerCapitaGrp ? x.grpPC : x.grp
      ),
      name: "BRP (tusen kronor) ",
    });

    // Co2
    var emissionsPerYear = Helper.groupBy(
      this.data.emissions.filter((x) =>
        this.data.grps.find((y) => y.year == x.year)
      ),
      (e: any) => e.year
    );

    series.push({
      data: emissionsPerYear.map((x) =>
        x
          .map((y) => (this.showPerCapitaGrp ? y.co2PC : y.co2))
          .reduce((a, b) => Utils.round(a + b))
      ),
      name: "CO\u2082 (ton)",
    });

    return series;
  }

  public get grpLabels() {
    return this.data.grps.map((x) => x.year);
  }

  public get emissionSeries() {
    var series = [];
    var sectorsToShow = this.getSelectedSectors();

    for (var i in sectorsToShow) {
      series.push({
        data: this.data.emissions
          .filter((x) => x.sectorId === sectorsToShow[i].id)
          .map((x) => (this.showPerCapita ? x.co2PC : x.co2)),
        name: sectorsToShow[i].label,
        type: this.emissionChartType,
      });

      // sverige
      if (this.showComparsion) {
        series.push({
          data: this.data.emissions
            .filter((x) => x.sectorId === sectorsToShow[i].id)
            .map((x) => x.co2TPC),
          name: "Genomsnitt i riket",
          type: "line",
          stroke: {},
        });
      }
    }

    return series;
  }

  public get emissionLabels() {
    return this.data.emissions
      .filter((x) => x.sectorId === this.sectors[0].id)
      .map((x) => x.year);
  }

  public selectSector(sector: any) {
    this.selectedSector = sector;
    this.isSectorDropdownOpen = false;
  }

  public getSectorTree() {
    const sectors = this.parentSectors;
    for (const i in sectors) {
      sectors[i].label = sectors[i].category;
      sectors[i].children = this.data.sectors.filter(
        (x) => x.category === sectors[i].category && x.name != "Alla"
      );
      for (const n in sectors[i].children)
        sectors[i].children[n].label = sectors[i].children[n].name;
    }

    return sectors;
  }
}
