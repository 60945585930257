import { store } from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import apiService from "@/services/api";
import Vue from "vue";
import {
  IAppState,
  IEmission,
  IEmissionType,
  IEmissionType2,
  IEmissionTypeNode,
  IEnergyFilter,
  IFilter,
  IMonthlyEmission,
  IOrganisation,
  IOrganisationUnit,
  IOrgUnitNode,
  IYearlyEmission,
} from "../../models/models";
import Helper from "../../utils/helper";
import _ from "lodash";
import { ChartSubTitle } from "@/components/energy/energyDataEnums";

const name = "organisation";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ namespaced: true, name: name, dynamic: true, store })
class OrganisationModule extends VuexModule implements IAppState {
  private _isLoading: boolean = true;
  private _isInitializing: boolean = true;
  private _organisation: IOrganisation = null;
  private _emissionsPerYear: Record<number, IEmission[]> = {};
  private _filter: IFilter = null;

  @Mutation
  private commitFilter(filter: IFilter) {
    this._filter = filter;
  }

  @Mutation
  private commitOrganisation(organisation: IOrganisation) {
    this._organisation = organisation;
  }

  @Mutation
  private commitIsInitializing(value: boolean) {
    this._isInitializing = value;
  }

  @Mutation
  private commitIsLoading(value: boolean) {
    this._isLoading = value;
  }

  @Mutation
  private commitEmissionsPerYear(payload: {
    year: number;
    emissions: IEmission[];
  }) {
    Vue.set(this._emissionsPerYear, payload.year, payload.emissions);
  }

  @Action({ rawError: true })
  public async setFilter(filter: IFilter) {
    this.commitFilter(filter);

    await this.fetchEmissions({
      key: this._organisation.Organisation.Key,
      years: [filter.year, filter.comparsionYear],
    });
  }

  @Action({ rawError: true })
  public async setOrgUnit(orgUnit: IOrganisationUnit) {
    const filter = this._filter;
    filter.orgUnit = orgUnit;
    await this.setFilter(filter);
  }

  @Action({ rawError: true })
  public async setComparsionYear(value: number) {
    const filter = this._filter;
    filter.comparsionYear = value;
    await this.setFilter(filter);
  }

  @Action({ rawError: true })
  public async setYear(value: number) {
    let comparsionYear = value - 1;
    if (this.years.indexOf(comparsionYear) == -1) {
      comparsionYear = null;
    }

    const filter = this._filter;
    filter.year = value;
    filter.comparsionYear = comparsionYear;
    await this.setFilter(filter);
  }

  @Action({ rawError: true })
  public async setStartMonth(value: number) {
    const filter = this._filter;
    filter.startMonth = value;
    if (filter.endMonth < filter.startMonth)
      filter.endMonth = filter.startMonth;

    await this.setFilter(filter);
  }

  @Action({ rawError: true })
  public async setEndMonth(value: number) {
    const filter = this._filter;
    filter.endMonth = value;
    await this.setFilter(filter);
  }

  @Action({ rawError: true })
  public async setEmissionType(value: IEmissionType) {
    const filter = this._filter;
    filter.emissionType = value;
    this.setFilter(filter);
    //await this.updateOrgUnitTree(this.year);
  }

  @Action({ rawError: true })
  public async initialize(key: string): Promise<void> {
    this.setIsInitializing(true);

    var response = await apiService.getGeneralData(key);
    response.Years = response.Years.sort((y1, y2) => y2 - y1);
    response.Activities = [
      {
        Id: 1,
        Name: "Alla",
        ParentId: 0,
        Children: response.Activities,
      },
    ];

    for (var i in response.Activities[0].Children)
      response.Activities[0].Children[i].ParentId = 1;

    for (var i in response.EmissionTypes) {
      if (response.EmissionTypes[i].ParentId == 0)
        response.EmissionTypes[i].ParentId = 1;
    }

    response.EmissionTypes.push({
      Id: 1,
      Name: "Alla",
      ParentId: 0,
    } as IEmissionType2);

    this.commitOrganisation(response);

    // TODO: years[1] may fail
    await this.setFilter({
      orgUnit: response.OrgUnits[0],
      emissionType: response.Activities[0],
      year: response.Years[0],
      comparsionYear: response.Years[1],
      startMonth: 1,
      endMonth: 12,
    });

    this.setIsInitializing(false);
  }

  @Action({ rawError: true })
  private setIsLoading(value: boolean) {
    this.commitIsLoading(value);
  }

  @Action({ rawError: true })
  private setIsInitializing(value: boolean) {
    this.commitIsInitializing(value);
  }

  @Action({ rawError: true })
  private async fetchEmissions(payload: { key: string; years: number[] }) {
    this.setIsLoading(true);

    const yearsToFetch = [];
    for (var i in payload.years) {
      if (!this._emissionsPerYear[payload.years[i]] && payload.years[i]) {
        yearsToFetch.push(payload.years[i]);
      }
    }

    if (yearsToFetch && yearsToFetch.length > 0) {
      console.time("Fetching emissions for " + payload.years.join(", "));
      var response = await apiService.getEmissionData(
        payload.key,
        yearsToFetch
      );

      for (var i in response) {
        this.commitEmissionsPerYear({
          year: response[i].Year,
          emissions: response[i].Emissions.map(x => {
            return {
              ...x,
              Scope: !x.Scope ? null
                : parseInt(x.Scope.split(".")[0]),
          }}),
        });
      }

      console.timeEnd("Fetching emissions for " + payload.years.join(", "));
    }

    this.setIsLoading(false);
  }

  public get isLoading() {
    return this._isLoading;
  }

  public get isInitializing() {
    return this._isInitializing;
  }

  public get filter() {
    return this._filter;
  }

  public get orgUnit() {
    return this.filter.orgUnit;
  }

  public get comparsionYear() {
    return this.filter.comparsionYear;
  }

  public get year() {
    return this.filter.year;
  }

  public get startMonth() {
    return this.filter.startMonth;
  }

  public get endMonth() {
    return this.filter.endMonth;
  }

  public get general() {
    return this._organisation;
  }

  public get emissionType() {
    return this.filter.emissionType;
  }

  public get name() {
    return this._organisation.Organisation.Name;
  }

  public get orgUnits() {
    return this._organisation.OrgUnits;
  }

  public get years() {
    return this._organisation.Years;
  }

  public get comparsionYears() {
    return this._organisation.Years;
  }

  public get emissionTypes() {
    return this._organisation.Activities;
  }

  public get emissionsForYear() {
    return this._emissionsPerYear[this.year];
  }

  public get emissionsForComparsionYear() {
    return this._emissionsPerYear[this.comparsionYear];
  }

  public get areaId() {
    return this._organisation.Organisation.AreaId;
  }

  public get getFilteredEmissions(): IEmission[] {
    return Helper.filterEmissions(
      this._emissionsPerYear[this.year],
      this.startMonth,
      this.endMonth,
      this.orgUnit.Id == this._organisation.OrgUnits[0].Id
        ? null
        : this.orgUnit,
      this.emissionType.Id == this._organisation.Activities[0].Id
        ? null
        : this.emissionType
    );
  }

  // Gets static years instead of a dynamic one
  public get getStaticFilteredEmissions(): IEmission[] {
    var collectedYearsData = [];

    // TODO:
    // helper filterEmissions retrieves range from api, which
    // removes the static purpose

    this.years.forEach((year) => {
      if (this._emissionsPerYear[year] !== undefined) {
        var x = Helper.filterEmissions(
          this._emissionsPerYear[year],
          1,
          12,
          this.orgUnit.Id == this._organisation.OrgUnits[0].Id
            ? null
            : this.orgUnit,
          this.emissionType.Id == this._organisation.Activities[0].Id
            ? null
            : this.emissionType
        );
        x !== undefined
          ? (collectedYearsData = collectedYearsData.concat(x))
          : null;
      }
    });
    return collectedYearsData;
  }

  public get getFilteredEmissionsForComparsionYear(): IEmission[] {
    return Helper.filterEmissions(
      this._emissionsPerYear[this.comparsionYear],
      this.startMonth,
      this.endMonth,
      this.orgUnit.Id == this._organisation.OrgUnits[0].Id
        ? null
        : this.orgUnit,
      this.emissionType.Id == this._organisation.Activities[0].Id
        ? null
        : this.emissionType
    );
  }

  public get getEmissionTypeTree(): IEmissionTypeNode[] {
    console.time("getEmissionTypeTree");

    const emissionTypes = this._organisation.EmissionTypes;
    const emissions = this.getFilteredEmissions;

    const hashTable = {};
    emissionTypes.forEach(
      (aData) =>
        (hashTable[aData.Id] = {
          ...aData,
          Children: [],
          Co2: 0,
          TotalCo2: 0,
          Cost: 0,
          TotalCost: 0,
          Quantity: 0,
          TotalQuantity: 0,
        } as IEmissionTypeNode)
    );
    const dataTree = [];
    emissionTypes.forEach((aData) => {
      const node = hashTable[aData.Id] as IEmissionTypeNode;
      if (aData.ParentId > 0) {
        hashTable[aData.ParentId].Children.push(node);
      } else {
        dataTree.push(node);
      }
    });

    Helper.updateEmissionTypeTreeRecursive(dataTree, emissions);
    console.timeEnd("getEmissionTypeTree");

    return dataTree;
  }

  // Gets static years instead of a dynamic one
  public get getStaticEmissionTypeTree(): IEmissionTypeNode[] {
    console.time("getEmissionTypeTree");

    const emissionTypes = this._organisation.EmissionTypes;
    const emissions = this.getStaticFilteredEmissions;

    const hashTable = {};
    emissionTypes.forEach(
      (aData) =>
        (hashTable[aData.Id] = {
          ...aData,
          Children: [],
          Co2: 0,
          TotalCo2: 0,
          Cost: 0,
          TotalCost: 0,
          Quantity: 0,
          TotalQuantity: 0,
        } as IEmissionTypeNode)
    );
    const dataTree = [];
    emissionTypes.forEach((aData) => {
      const node = hashTable[aData.Id] as IEmissionTypeNode;
      if (aData.ParentId > 0) {
        hashTable[aData.ParentId].Children.push(node);
      } else {
        dataTree.push(node);
      }
    });

    Helper.updateEmissionTypeTreeRecursive(dataTree, emissions);
    console.timeEnd("getEmissionTypeTree");

    return dataTree;
  }

  public get getOrgUnitTree(): IOrgUnitNode[] {
    console.time("getOrgUnitTree");

    const orgUnits = this._organisation.OrgUnits2;
    const emissions = this.getFilteredEmissions;

    const hashTable = {};
    orgUnits.forEach(
      (aData) =>
        (hashTable[aData.Id] = {
          ...aData,
          Children: [],
          Co2: 0,
          TotalCo2: 0,
          Cost: 0,
          TotalCost: 0,
          Emissions: [],
        } as IOrgUnitNode)
    );

    const dataTree = [];
    orgUnits.forEach((aData) => {
      const node = hashTable[aData.Id] as IOrgUnitNode;
      if (aData.ParentId > 0) {
        hashTable[aData.ParentId].Children.push(node);
      } else {
        dataTree.push(node);
      }
    });

    Helper.updateOrgUnitTreeRecursive(dataTree, emissions);

    console.timeEnd("getOrgUnitTree");

    return dataTree;
  }

  public get getEmissionsPerMonth(): IMonthlyEmission[] {
    return this._organisation.EmissionsPerMonth;
  }

  public get getStartMonths() {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  }

  public get getEndMonths() {
    const length = 12 - this.startMonth + 1;
    return Array.from({ length: length }, (_, i) => i + this.startMonth);
  }

  public get getCo2PerMonthInTonForCurrentYear(): number[] {
    const data = this._organisation.EmissionsPerMonth.filter(
      (x) => x.Year == this.year
    );

    return Array.from(Helper.range(12, 1), (i) => {
      const month = data.find((x) => x.Month == i);
      if (month) return Helper.round(month.Co2 / 1000);
      return 0;
    });
  }

  public get getCo2PerYear() {
    const emissions = this._organisation.EmissionsPerMonth;
    const result = new Map<number, number>();

    for (const { Year, Co2 } of emissions)
      result.set(Year, (result.get(Year) || 0) + Co2);

    return [...result].map((x) => ({ year: x[0], co2: x[1] }));
  }

  public get getCo2PerEmissionType() {
    const emissions = this.getFilteredEmissions;
    const result = new Map<number, number>();

    for (const { EmissionTypeId, Co2 } of emissions)
      result.set(EmissionTypeId, (result.get(EmissionTypeId) || 0) + Co2);

    return [...result]
      .filter((x) => Math.round(x[1]) > 0)
      .map((x) => ({
        name: Helper.getEmissionTypeById(x[0], this._organisation.Activities)
          .Name,
        co2: Math.round(x[1]),
      }));
  }

  public get getAccumulatedCo2PerMonthInTonForPreviousYear(): number[] {
    let sum = 0;
    return this._organisation.EmissionsPerMonth.filter(
      (x) => x.Year == this.comparsionYear
    ).map((x) => Helper.round((sum += x.Co2 / 1000)));
  }

  public get getAccumulatedCo2PerMonthInTonForYear(): number[] {
    let sum = 0;
    return this._organisation.EmissionsPerMonth.filter(
      (x) => x.Year == this.year
    ).map((x) => Helper.round((sum += x.Co2 / 1000)));
  }

  public get getYearlyEmissionsByEmissionTypeForCurrentYear(): IYearlyEmission[] {
    return this._organisation.EmissionsPerEmissionType.filter(
      (x) => x.Year == this.year
    );
  }

  public get getCo2PerEmissionTypeForCurrentOrgUnitAndPeriod() {
    console.time("getCo2PerEmissionTypeForCurrentOrgUnitAndPeriod");

    const orgUnitIds = Helper.getAllOrgUnitChildren(this.orgUnit);
    let emissions = (this._emissionsPerYear[this.year] ?? [])
      .filter((x) => x.Month >= this.startMonth && x.Month <= this.endMonth)
      .filter((x) => orgUnitIds.indexOf(x.OrgUnitId) > -1);

    const emissionsPerType = Helper.groupBy(emissions, (e) => e.EmissionTypeId);
    let result = [];
    for (var i in emissionsPerType) {
      if (emissionsPerType[i].length > 0) {
        const co2 = emissionsPerType[i].reduce(
          (sum, current) => sum + current.Co2,
          0
        );

        if (co2 > 0) {
          result.push({
            Name: Helper.getEmissionTypeById(
              emissionsPerType[i][0].EmissionTypeId,
              this._organisation.Activities
            ).Name,
            Co2: emissionsPerType[i].reduce(
              (sum, current) => sum + current.Co2,
              0
            ),
          });
        }
      }
    }

    result = result.sort((a, b) => b.Co2 - a.Co2);

    console.timeEnd("getCo2PerEmissionTypeForCurrentOrgUnitAndPeriod");

    return result;
  }

  public get getCo2PerOrgUnitForCurrentOrgUnitAndPeriod() {
    console.time("getCo2PerOrgUnitForCurrentOrgUnitAndPeriod");

    const orgUnits = [...this.orgUnit.Children];
    if (this.orgUnit.Id === this.orgUnits[0].Id) {
      orgUnits.push({
        Name: this.orgUnit.Name,
        Children: [],
        CostCenter: this.orgUnit.CostCenter,
        Emissions: [],
        Id: this.orgUnit.Id,
      });
    }

    const emissions = this._emissionsPerYear[this.year].filter(
      (x) => x.Month >= this.startMonth && x.Month <= this.endMonth
    );
    const result = {
      categories: orgUnits.map((x) => x.Name),
      data: [],
    };

    const emissionsPerType = Helper.groupBy(emissions, (e) => e.EmissionTypeId);

    for (var i in emissionsPerType) {
      const d = {
        name: Helper.getEmissionTypeById(
          emissionsPerType[i][0].EmissionTypeId,
          this._organisation.Activities
        ).Name,
        data: [],
      };
      result.data.push(d);

      for (var org in orgUnits) {
        const orgUnit = orgUnits[org];
        const orgUnitIds = Helper.getAllOrgUnitChildren(orgUnit);
        d.data.push(
          emissionsPerType[i]
            .filter(function(e) {
              return this.indexOf(e.OrgUnitId) > -1;
            }, orgUnitIds)
            .reduce((sum, current) => sum + current.Co2, 0)
        );
      }
    }

    console.timeEnd("getCo2PerOrgUnitForCurrentOrgUnitAndPeriod");

    return result;
  }

  // Returns the total emissions of each year
  public get getStaticCo2PerOrgUnitForCurrentOrgUnitAndPeriod() {
    const orgUnits = [...this.orgUnits[0].Children];

    // Calculates the total emissions of each year
    var emissions = [];
    this.years.forEach((year) => {
      if (this._emissionsPerYear[year] !== undefined) {
        const emission = this._emissionsPerYear[year].filter(
          (x) => x.Month >= 1 && x.Month <= 12
        );
        emission !== undefined
          ? (emissions = emissions.concat(emission))
          : null;
      }
    });

    const result = {
      categories: orgUnits.map((x) => x.Name),
      data: [],
    };

    const emissionsPerType = Helper.groupBy(emissions, (e) => e.EmissionTypeId);

    for (var i in emissionsPerType) {
      const d = {
        name: Helper.getEmissionTypeById(
          emissionsPerType[i][0].EmissionTypeId,
          this._organisation.Activities
        ).Name,
        data: [],
      };
      result.data.push(d);

      for (var o in orgUnits) {
        const orgUnit = orgUnits[o];
        const orgUnitIds = Helper.getAllOrgUnitChildren(orgUnit);
        d.data.push(
          emissionsPerType[i]
            .filter(function(e) {
              return this.indexOf(e.OrgUnitId) > -1;
            }, orgUnitIds)
            .reduce((sum, current) => sum + current.Co2, 0)
        );
      }
    }

    return result;
  }

  // Energydata
  private _energyFilter: IEnergyFilter = null;
  private _energyEmissions: IYearlyEmission[] = null;

  public get energyFilter(): IEnergyFilter {
    return this._energyFilter;
  }

  public get energyEmissions(): IYearlyEmission[] {
    return this._energyEmissions;
  }

  @Mutation
  private commitEnergyFilter(energyFilter: IEnergyFilter) {
    this._energyFilter = energyFilter;
  }

  @Mutation
  private commitEnergyEmissions(energyEmissions: IYearlyEmission[]) {
    this._energyEmissions = energyEmissions;
  }

  @Action({ rawError: true })
  public async setEnergyFilter(energyFilter: IEnergyFilter) {
    this.commitEnergyFilter(energyFilter);

    let filteredEnergyEmissions = this._organisation.EmissionsPerEmissionType.filter(
      (x) =>
        !(
          x.AsLiter === null &&
          x.Kwh === null &&
          x.RenewablePercentage === null
        )
    );

    if (this._energyFilter.year) {
      filteredEnergyEmissions = filteredEnergyEmissions.filter(
        (x) => x.Year === this._energyFilter.year
      );
    }

    if (this._energyFilter.energy) {
      switch (this._energyFilter.energy) {
        case ChartSubTitle.RENEWABLE:
          filteredEnergyEmissions = filteredEnergyEmissions.filter(
            (x) => x.RenewablePercentage > 0
          );
          break;
        case ChartSubTitle.FOSSIL:
          filteredEnergyEmissions = filteredEnergyEmissions.filter(
            (x) => x.RenewablePercentage < 100
          );
          break;
        case ChartSubTitle.FUEL:
          filteredEnergyEmissions = filteredEnergyEmissions.filter(
            (x) => x.AsLiter !== null
          );
          break;
      }
    }

    // Commit the filtered results to _energyEmissions
    this.commitEnergyEmissions(filteredEnergyEmissions);
  }

  public get yearlyMwh() {
    const emissions = this._organisation.EmissionsPerEmissionType.filter(
      (x) =>
        !(
          x.AsLiter === null &&
          x.Kwh === null &&
          x.RenewablePercentage === null
        )
    );
    const yearlyMwh = new Map<number, { renewable: number; fossil: number }>();

    for (const emission of emissions) {
      if (yearlyMwh.has(emission.Year)) {
        const currentYear = yearlyMwh.get(emission.Year);

        yearlyMwh.set(emission.Year, {
          renewable:
            currentYear.renewable +
            (emission.Kwh / 1000) * (emission.RenewablePercentage / 100),
          fossil:
            currentYear.fossil +
            (emission.Kwh / 1000) *
              ((100 - emission.RenewablePercentage) / 100),
        });
      } else {
        yearlyMwh.set(emission.Year, {
          renewable:
            (emission.Kwh / 1000) * (emission.RenewablePercentage / 100),
          fossil:
            (emission.Kwh / 1000) *
            ((100 - emission.RenewablePercentage) / 100),
        });
      }
    }

    return yearlyMwh;
  }
}

export const organisationModule = getModule(OrganisationModule);
