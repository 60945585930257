

















import { Vue, Component } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import defaultChartOptions from "@/models/defaultChartOptions";
import { organisationModule } from "@/store/modules/organisation";
import _ from "lodash";
import { ApexOptions } from "apexcharts";

@Component({
  components: {
    apexchart: VueApexCharts,
  },
})
export default class AccumulatedChart extends Vue {
  public get organisation() {
    return organisationModule;
  }

  public get options() {
    const options: ApexOptions = {
      stroke: {
        width: [0, 4, 4, 4],
        dashArray: [0, 0, 0, 5],
      },
      legend: {
        show: true,
      },
      subtitle: {
        text: "",
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return val + " ton CO<sub>2</sub>";
          },
        },
      },
      colors: ["#f39200", "#146e71", "#1c98a2", "#a8dadc"],
      xaxis: {
        categories: moment
          .monthsShort()
          .map((m) => this.$options.filters.capitalize(m)),
      },
      title: {
        text: "Utsläpp " + this.organisation.year + " i ton koldioxid",
      },
      yaxis: [
        {
          title: {
            text: "CO\u2082 (ton)",
          },
        },
        {
          seriesName: "Ack. utsläpp " + this.organisation.comparsionYear,
          opposite: true,
          show: true,
        },
        {
          seriesName: "Ack. utsläpp " + this.organisation.comparsionYear,
          opposite: true,
          show: false,
        },
      ],
    };

    return _.merge({}, defaultChartOptions, options);
  }

  get series() {
    const series = [
      {
        type: "column",
        name: this.organisation.year,
        data: this.organisation.getCo2PerMonthInTonForCurrentYear,
      },
      {
        type: "line",
        name: "Ack. utsläpp " + this.organisation.year,
        data: this.organisation.getAccumulatedCo2PerMonthInTonForYear,
      },
      {
        type: "line",
        name: "Ack. utsläpp " + this.organisation.comparsionYear,
        data: this.organisation.getAccumulatedCo2PerMonthInTonForPreviousYear,
      },
    ];

    return series;
  }
}
