<template>
  <div v-click-outside="clickOutside" class="dropdown">
    <!-- Toggle -->
    <component
      :is="toggleTag"
      :disabled="isDisabled"
      class="dropdown-toggle"
      :class="toggleClasses"
      @click.prevent="toggleIsOpenContent"
    >
      <div class="dropdown-toggle-content" :class="toggleContentClasses">
        <slot name="toggle-content"></slot>
      </div>
      <!--<span
        v-if="toggleIcon"
        class="dropdown-toggle-icon"
        :class="toggleIconClasses"
        v-html="toggleIcon"
      ></span> -->
    </component>
    <!-- Content -->
    <div
      v-show="isOpenContent"
      @click="clickContent"
      class="dropdown-content"
      :class="contentClasses"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
// Example
// <dropdown-component :content-is-nav=true>
// 	<template v-slot:toggle-content>
// 		Open
// 	</template>
// 		<%= link_to "Dina sidor", edit_user_registration_path, tabindex: -1 %>
// 		<%= button_to "Logga ut", destroy_user_session_path, method: :delete %>
// </dropdown-component>
import "@/common/directives/clickOutside";

export default {
  props: {
    toggleTag: { default: "button" },
    stripToggleButton: { default: true },
    toggleClass: String,
    toggleIcon: { default: `<i class="fa fa-caret-down"></i>` },
    toggleIconClass: { type: String }, // eg: 'has-rotation-90'
    toggleContentClass: String,
    contentClass: String,
    contentIsNav: { default: false }, // sets content class is-nav
    contentCloseOnClick: { default: true },
    contentWidth: { default: null }, // sets content class is-${width}
    contentPosition: { default: null }, // sets content class is-${position}
    closeOutside: { default: true },
    isOpen: { default: false },
    isDisabled: { default: false },
  },

  data() {
    return {
      isOpenContent: this.isOpen,
    };
  },

  watch: {
    isOpen: function(val) {
      this.isOpenContent = val;
    },
  },

  computed: {
    toggleClasses() {
      let classes = "";
      if (this.toggleClass) classes += this.toggleClass;
      if (this.stripToggleButton) classes += " is-stripped";
      return classes;
    },
    toggleContentClasses() {
      return this.toggleContentClass ? this.toggleContentClass : "";
    },
    toggleIconClasses() {
      let classes = "";
      if (this.toggleIconClass) classes += ` ${this.toggleIconClass}`;
      if (this.isOpenContent) classes += " is-open";

      return classes;
    },
    contentClasses() {
      let classes = "";
      if (this.contentClass) classes += ` ${this.contentClass}`;
      if (this.contentIsNav) classes += " is-nav";
      if (this.contentPosition) classes += ` is-${this.contentPosition}`;
      if (this.contentWidth) classes += ` is-${this.contentWidth}`;

      return classes;
    },
  },

  methods: {
    toggleIsOpenContent() {
      this.isOpenContent = !this.isOpenContent;
      if (this.isOpenContent) {
        this.$emit("open");
      } else {
        this.$emit("close");
      }
      // Could add focusing here...
    },
    clickOutside() {
      if (this.closeOutside) {
        this.isOpenContent = false;
      }
    },
    clickContent() {
      if (this.contentIsNav && this.contentCloseOnClick) {
        this.isOpenContent = false;
      }
    },
  },
};
</script>
