<template>
    <ul class="tree-menu">
        <li class="tree-menu-row" :class="`is-depth-${depth}`">
            <span class="tree-menu-label-wrapper">
                <i class="tree-menu-label-icon"
                   @click="toggleChildren"
                   :class="getIconClass()">
                </i>

                <a href="" @click.prevent="treeMenuClickLabel(parent)">{{ parent[label] }}</a>
            </span>

            <template v-if="showChildren">
                <tree-menu @treeMenuClickLabel="treeMenuClickLabel"
                           v-for="child in parent[children]"
                           :children="children"
                           :parent="child"
                           :key="child.Id ? child.Id + 'name' : child.id + 'name' "
                           :depth="depth + 1"
                           :openDepth="openDepth"
                           :label="label">
                </tree-menu>
            </template>
        </li>
    </ul>
</template>
<script>
    export default {
        props: {
            parent: Object,
            children: {
                type: String,
                default: 'Children'
            },
            label: String, // parent key
            depth: {
                type: [Number, String],
                default: 0,
            },
            openDepth: {
                type: [Number, String],
                default: -1 // if 0 first level will be opened etc
            }
        },
        name: 'tree-menu',

        data() {
            return {
                showChildren: this.depth <= this.openDepth,
            }
        },

        methods: {
            toggleChildren() {
                this.showChildren = !this.showChildren;
            },

            getIconClass() {
                if (this.parent[this.children] && this.parent[this.children].length) {
                    return this.showChildren ? 'fa fa-minus-square-o' : 'fa fa-plus-square-o';
                }
                return ''
            },

            treeMenuClickLabel(parent) {
                this.$emit('treeMenuClickLabel', parent);
            }
        }
    }
</script>
