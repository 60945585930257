






























import ActivityChart from "../components/overview/ActivityChart.vue";
import AccumulatedChart from "../components/overview/AccumulatedChart.vue";
import YearlyChart from "../components/overview/YearlyChart.vue";
import { Vue, Component } from "vue-property-decorator";
import Dropdown from "../components/shared/Dropdown.vue";
import FilterBar from "../components/shared/FilterBar.vue";
import { organisationModule } from "@/store/modules/organisation";

@Component({
  components: {
    AccumulatedChart,
    ActivityChart,
    YearlyChart,
    Dropdown,
    FilterBar,
  },
})
export default class StartView extends Vue {
  get organisation() {
    return organisationModule;
  }

  async selectYear(year) {
    await this.organisation.setYear(year);
  }
}
