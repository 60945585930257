









import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Button extends Vue {
  @Prop({ default: "Button" }) readonly title!: string;
  @Prop({ default: "" }) readonly iconClass!: string;
  @Prop({ default: "#" }) readonly redirectUrl!: string;
}
