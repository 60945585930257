














import { Vue, Component, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import defaultChartOptions from "@/models/defaultChartOptions";
import { organisationModule } from "@/store/modules/organisation";
import { ApexOptions } from "apexcharts";
import _ from "lodash";

@Component({
  components: {
    apexchart: VueApexCharts,
  },
})
export default class ActivityGraph extends Vue {
  @Prop({ default: "450" }) readonly chartHeight!: string;

  public get organisation() {
    return organisationModule;
  }

  get options() {
    const options: ApexOptions = {
      plotOptions: {
        bar: {
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return this.$options.filters.number(val) + " kg CO<sub>2</sub>";
          },
        },
      },
      title: {
        text: "Utsläpp per aktivitet",
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: this.getActivityData.map((x) => x.Name),
      },
      yaxis: {
        title: {
          text: "CO\u2082 (kg)",
        },
      },
      subtitle: {
        text:
          "För " +
          this.organisation.orgUnit.Name +
          " - " +
          this.organisation.year,
      },
      colors: [
        "#1c98a2",
        "#f39200",
        "#fac228",
        "#146e71",
        "#FFE499",
        "#a8dadc",
        "#152b71",
      ],
    };

    return _.merge({}, defaultChartOptions, options);
  }

  get getActivityData() {
    return (
      this.organisation.getCo2PerEmissionTypeForCurrentOrgUnitAndPeriod ?? []
    );
  }

  get series() {
    const series = [
      {
        name: this.organisation.year.toString(),
        data:
          this.getActivityData.length > 0
            ? this.getActivityData.map((x) => Math.round(x.Co2))
            : [],
      },
    ];
    return series;
  }
}
