

























import { Vue, Component, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import defaultChartOptions from "@/models/defaultChartOptions";
import { organisationModule } from "@/store/modules/organisation";
import Helper from "../../utils/helper";
import { ApexOptions } from "apexcharts";
import _ from "lodash";

@Component({
  components: {
    apexchart: VueApexCharts,
  },
})
export default class ActivityChart extends Vue {
  @Prop({ default: "350" }) readonly chartHeight!: string;

  public get organisation() {
    return organisationModule;
  }

  public get options() {
    const options: ApexOptions = {
      plotOptions: {
        bar: {
          distributed: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        formatter: (value) => {
          return value + " ton";
        },
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return value + " ton CO<sub>2</sub>";
          }.bind(this),
        },
      },
      title: {
        text: "Största utsläppen " + this.organisation.year,
      },
      yaxis: {
        title: {
          text: "CO\u2082 (ton)",
        },
      },
      xaxis: {
        categories: this.getEmissionsOver10Percent
          ? this.getEmissionsOver10Percent.map((x) => x.Name)
          : [],
      },
      subtitle: {
        text: "",
      },
    };

    return _.merge({}, defaultChartOptions, options);
  }

  public get getEmissionsOver10Percent() {
    let emissions = this.organisation
      .getYearlyEmissionsByEmissionTypeForCurrentYear;
    let accumulatedOtherCO2 = 0;
    let total = emissions.reduce((a, b) => a + b.Co2, 0);
    let sorted = emissions.sort((a, b) => b.Co2 - a.Co2);
    let data = [];

    for (let i = 0; i < sorted.length; i++) {
      if (sorted[i].Co2 > total * 0.1) {
        data.push(sorted[i]);
      } else {
        accumulatedOtherCO2 += sorted[i].Co2;
      }
    }

    let other = { Name: "Övrigt", Co2: accumulatedOtherCO2 };
    data.push(other);

    return data;
  }

  public get series() {
    const series = [
      {
        name: "Totalt",
        data: this.getEmissionsOver10Percent.map((x) =>
          Helper.round(x.Co2 / 1000)
        ),
      },
    ];
    return series;
  }
}
