























import { Vue, Component, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import defaultChartOptions from "@/models/defaultChartOptions";
import { organisationModule } from "@/store/modules/organisation";
import Helper from "@/utils/helper";
import { ApexOptions } from "apexcharts";
import _ from "lodash";

@Component({
  components: {
    apexchart: VueApexCharts,
  },
})
export default class PieChart extends Vue {
  // Props
  @Prop({ default: "450" }) readonly chartHeight!: string;
  @Prop({ default: "" }) readonly chartTitle!: string;
  @Prop({ default: "left" }) readonly chartTitleAlign!: string;

  public get organisation() {
    return organisationModule;
  }

  get options() {
    const options: ApexOptions = {
      chart: {
        events: {
          dataPointSelection: this.dataPointSelection,
          dataPointMouseEnter: function(event, chartContext, config) {
            document.body.style.cursor = "pointer";
          },
          dataPointMouseLeave: function(event, chartContext, config) {
            document.body.style.removeProperty("cursor");
          },
        },
      },
      labels: this.emissionTypes.map((x) => x.Name),
      legend: {
        position: "bottom",
      },
      dataLabels: {
        enabled: true,
        dropShadow: {
          enabled: false,
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            return this.$options.filters.number(value) + " kg CO<sub>2</sub>";
          },
        },
      },
      title: {
        text:
          this.chartTitle === ""
            ? this.organisation.orgUnit.Name + " koldioxidutsläpp"
            : this.chartTitle,
      },
      colors: [
        "#a8dadc",
        "#146e71",
        "#1c98a2",
        "#f39200",
        "#fac228",
        "#ffe499",
        "#c5a67b",
        "#b15dd2",
        "#82b582",
        "#c2c3c4",
        "#ed7ee1",
        "#60d1c8",
        "#74db87",
        "#f25058",
      ],
    };

    return _.merge({}, defaultChartOptions, options);
  }

  get showCategories() {
    return !this.organisation.emissionType;
  }

  get series() {
    return this.emissionTypes.map((x) => Math.round(x.TotalCo2));
  }

  get emissionTypes() {
    const tree = this.organisation.getEmissionTypeTree;
    const node = Helper.getEmissionTypeById2(
      this.organisation.emissionType.Id,
      tree
    );
    return node.Children;
  }

  dataPointSelection(event, chartContext, config) {
    const selectedName = this.options.labels[config.dataPointIndex];
    const filtered = this.emissionTypes.filter((x) => x.Name === selectedName);
    if (filtered && filtered.length > 0)
      this.organisation.setEmissionType(filtered[0]);
  }
}
