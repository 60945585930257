













import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Message extends Vue {
  private showMessage: Boolean = true;

  @Prop()
  private type: string = "";

  mounted() {
    this.$emit("openMessage");
  }

  close() {
    this.showMessage = false;
    this.$emit("closeMessage");
  }
}
