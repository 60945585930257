























import { Vue, Component } from "vue-property-decorator";
// import { organisationModule } from "@/store/modules/organisation";
import FilterBar from "../components/shared/FilterBar.vue";
import ActivityChart from "../components/comparison/ActivityGraph.vue";
import StackedBarChart from "../components/comparison/StackedBarChart.vue";

@Component({
  components: {
    FilterBar,
    ActivityChart,
    StackedBarChart,
  },
})
export default class StartView extends Vue {
  // get organisation() {
  //   return organisationModule;
  // }
}
