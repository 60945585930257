



























































































import { Vue, Component, Watch } from "vue-property-decorator";
import Dropdown from "../shared/Dropdown.vue";
import { organisationModule } from "@/store/modules/organisation";
import { IEmissionType, IEmissionTypeNode } from "@/models/models";
import Utils from "@/common/utils/utils";
import Helper from "@/utils/helper";

@Component({
  components: {
    Dropdown,
  },
})
export default class ActivityDetails extends Vue {
  nodesToDisplay: IEmissionTypeNode[] = [];
  openInfoId: number | null = null;

  created() {
    this.nodesToDisplay.push(this.emissionTypeTreeRoot);
  }

  @Watch("emissionTypeTree")
  onEmissionTypeTreeChanged(
    val: IEmissionTypeNode[],
    old: IEmissionTypeNode[]
  ) {
    if (val && val.length > 0) {
      this.updateEmissionTypeNode(val[0]);
    }
  }

  updateEmissionTypeNode(node: IEmissionTypeNode) {
    if (this.nodesToDisplay.some((x) => x.Id === node.Id)) {
      const index = this.nodesToDisplay.findIndex((x) => x.Id === node.Id);
      this.nodesToDisplay.splice(index, 1, node);
    }

    if (node.Children && node.Children.length !== 0) {
      for (const n of node.Children) {
        this.updateEmissionTypeNode(n);
      }
    }
  }

  get emissionTypeTree() {
    return organisationModule.getEmissionTypeTree;
  }

  get emissionTypeTreeRoot() {
    return organisationModule.getEmissionTypeTree[0];
  }

  // This has to be done to access Utils in the template
  get utils() {
    return Utils;
  }

  rowClass(node: IEmissionTypeNode): string {
    const classes: string[] = ["activity-details-table-row"];

    if (this.selectedRowID === node.Id) {
      classes.push("is-selected");
    }

    if (node.Children && node.Children.length > 0) {
      classes.push("has-children");

      if (this.nodesToDisplay.includes(node.Children[0])) {
        classes.push("is-open");
      }
    }

    const level = this.getLevel(node);
    classes.push(`is-depth-${level}`);

    return classes.join(" ");
  }

  toggleNode(node: IEmissionTypeNode, index: number) {
    const children = node.Children;
    if (!children || !children.length) return;

    if (this.isExpanded(node, index)) {
      // Remove visible children and their descendants
      let removeCount = 0;

      while (
        index + 1 + removeCount < this.nodesToDisplay.length &&
        this.isDescendant(node, this.nodesToDisplay[index + 1 + removeCount])
      ) {
        removeCount++;
      }

      this.nodesToDisplay.splice(index + 1, removeCount);
    } else {
      // Add children
      this.nodesToDisplay.splice(index + 1, 0, ...children);
    }
  }

  isDescendant(ancestor: IEmissionTypeNode, node: IEmissionTypeNode): boolean {
    let currentNode = node;
    while (currentNode) {
      if (currentNode === ancestor) return true;
      currentNode = this.nodesToDisplay.find(
        (n) => n.Children && n.Children.includes(currentNode)
      );
    }

    // In the current usage within the toggleNode method, isDescendant never returns false.
    return false;
  }

  getLevel(node: IEmissionTypeNode): number {
    let level = 0;
    let currentNode = node;
    while (currentNode) {
      currentNode = this.nodesToDisplay.find(
        (n) => n.Children && n.Children.includes(currentNode)
      );
      level++;
    }
    return level;
  }

  isExpanded(node: IEmissionTypeNode, index: number): boolean {
    const children = node.Children;
    if (!children || !children.length) return false;

    return (
      this.nodesToDisplay[index + 1] &&
      this.nodesToDisplay[index + 1].Id === children[0].Id
    );
  }

  openInfo(node: IEmissionTypeNode) {
    this.openInfoId = node.Id;
  }

  closeInfo() {
    this.openInfoId = null;
  }

  hasNoData(node: IEmissionTypeNode) {
    return (
      node.TotalCo2 === 0 && node.TotalCost === 0 && node.TotalQuantity === 0
    );
  }

  formatTotalCo2(node: IEmissionTypeNode) {
    this.hasNoData(node) ? "-" : Utils.localeFormatter(node.TotalCo2);
  }

  formatTotalCost(node: IEmissionTypeNode) {
    this.hasNoData(node) ? "-" : Utils.localeFormatter(node.TotalCost);
  }

  formatTotalQuantity(node: IEmissionTypeNode) {
    this.hasNoData(node) ? "-" : Utils.localeFormatter(node.TotalQuantity);
  }

  getEmissionType(node: IEmissionTypeNode): IEmissionType {
    return Helper.getEmissionTypeById(
      node.Id,
      organisationModule.emissionTypes
    );
  }

  get selectedRowID() {
    return organisationModule.emissionType.Id;
  }

  get isLoading() {
    return organisationModule.isLoading;
  }

  setActivity(node: IEmissionTypeNode) {
    const emissionType = this.getEmissionType(node);
    organisationModule.setEmissionType(emissionType);
  }

  clearActivity() {
    organisationModule.setEmissionType(organisationModule.emissionTypes[0]);
  }

  getScope(node: IEmissionTypeNode) {
    const emissionType = this.getEmissionType(node);
    return emissionType.Scope ? emissionType.Scope : "N/A";
  }
}
