import Vue from 'vue';
import Vuex from 'vuex';
import { IAppState } from '../models/models';

Vue.use(Vuex);
//import { emissionsModule } from './modules/EmissionsModule';


export interface IRootState {
    app: IAppState;
  }
  
  // Declare empty store first, dynamically register all modules later.
  
export const store = new Vuex.Store<IRootState>({});

// // Event handling
// store.subscribe(({ type, payload }, state) => {

//     console.log('mutation!!!!!!!!!!!!', type, payload, state);

//     // switch (type) {
//     //   case 'emissions/setCurrentOrgUnit':
        
//     //     store.state.emissions.go();
        
//     //     return store.dispatch('service/fetchData')

//     // }
//   })


