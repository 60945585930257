



























import { Vue, Component, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import defaultChartOptions from "@/models/defaultChartOptions";
import { organisationModule } from "@/store/modules/organisation";
import InfoIcon from "vue-material-design-icons/InformationOutline.vue";
import { ApexOptions } from "apexcharts";
import _ from "lodash";
import Utils from "@/common/utils/utils";

@Component({
  components: {
    apexchart: VueApexCharts,
    InfoIcon,
  },
})
export default class DonutChart extends Vue {
  // Props
  @Prop({ default: "450" }) readonly chartHeight!: string;
  @Prop({ default: "" }) readonly chartTitle!: string;
  @Prop({ default: "left" }) readonly chartTitleAlign!:
    | "left"
    | "right"
    | "center";
  @Prop({ default: "" }) readonly borderSettings!: string;

  public get organisation() {
    return organisationModule;
  }

  get options() {
    const options: ApexOptions = {
      chart: {
        events: {
          dataPointMouseEnter: () => {
            document.body.style.cursor = "pointer";
          },
          dataPointMouseLeave: () => {
            document.body.style.removeProperty("cursor");
          },
        },
      },
      labels: this.series.names,
      dataLabels: {
        enabled: false,
        dropShadow: {
          enabled: false,
        },
      },
      legend: {
        fontSize: "16px",
        formatter: (seriesName, opts) => {
          return `${Utils.truncate(seriesName, 18)} ${Utils.round(
            opts.w.globals.seriesPercent[opts.seriesIndex]
          )} %`;
        },
      },
      title: {
        align: this.chartTitleAlign,
      },
      tooltip: {
        y: {
          formatter: (value) => {
            return this.$options.filters.number(value) + " kg CO<sub>2</sub>";
          },
        },
      },
      colors: [
        "#1c98a2",
        "#f39200",
        "#b15dd2",
        "#82b582",
        "#c2c3c4",
        "#ed7ee1",
        "#60d1c8",
        "#74db87",
        "#f25058",
      ],
    };

    return _.merge({}, defaultChartOptions, options);
  }

  get series() {
    const emissions = this.organisation.general.EmissionsPerEmissionType.filter(
      (x) => x.Year == this.getPreviousOrMostRecentYear()
    )
      .map((x) => ({ name: x.Name, co2: x.Co2 }))
      .sort((a, b) => b.co2 - a.co2);

    const top5 = emissions.splice(0, 4);
    top5.push({
      name: "Övriga",
      co2: emissions.reduce((total, x) => total + x.co2, 0),
    });

    return {
      co2: top5.map((x) => x.co2),
      names: top5.map((x) => x.name),
    };
  }

  getPreviousOrMostRecentYear() {
    var year = new Date().getFullYear() - 1;
    if (this.organisation.general.Years.includes(year)) {
      return year;
    } else {
      return Math.max(...this.organisation.general.Years);
    }
  }
}
