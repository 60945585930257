enum ChartSubTitle {
  RENEWABLE = "Förnybart",
  FOSSIL = "Fossilt",
  FUEL = "Drivmedel",
}

enum EnergyDataUnit {
  MEGAWATT_HOURS = "MWh",
  LITER = "Liter",
  SWEDISH_CROWNS = "SEK",
}

export { ChartSubTitle, EnergyDataUnit };
