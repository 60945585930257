import axios from 'axios';

class Configuration {
    public Settings:
        {
            BaseUrl: ""
        };

    private readonly SettingsFile: string = process.env.VUE_APP_SETTINGSFILE;

    public async loadSettings(): Promise<any> {

        let response = await axios.get(this.SettingsFile);

        this.Settings = response.data;

        axios.defaults.headers.common['Pragma'] = 'no-cache';
        axios.defaults.headers.common['Cache-Control'] = 'no-cache';
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.baseURL = this.Settings.BaseUrl;
    }

}

export const Config = new Configuration();