import Vue from "vue";
import VueRouter from "vue-router";
import Details from "./views/Details.vue";
import Calculations from "./views/Calculations.vue";
import Overview from "./views/Overview.vue";
import Comparison from "./views/Comparison.vue";
import GeoArea from "./views/GeoArea.vue";
import EnergyData from "./views/EnergyData.vue";
import MunicipalOrg from "./views/MunicipalOrg.vue"
import Start from "./views/Start.vue"

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "redirect",
      beforeEnter(to, from, next) {
        window.location.href = "https://www.measureandchange.se";
      }
    },
    {
      path: "/:key/calculations",
      name: "calculations",
      component: Calculations
    },
    {
      path: "/:key/overview",
      name: "overview",
      component: Overview
    },
    {
      path: "/:key/details",
      name: "details",
      component: Details
    },
    {
      path: "/:key/comparison",
      name: "comparison",
      component: Comparison
    },
    {
      path: "/:key/geoarea",
      name: "geoarea",
      component: GeoArea
    },
    {
      path: "/:key/energydata",
      name: "energydata",
      component: EnergyData
    },
    {
      path: "/:key/municipal-organisation",
      name: "municipal-organisation",
      component: MunicipalOrg
    },
    {
      path: "/:key",
      name: "start",
      component: Start
    }

    //		{
    //      path: '/:key',
    //      component: () => import("./views/Start.vue"),
    //      name: 'start',
    //  }

  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
