import { ApexOptions } from "apexcharts";

const defaultChartLocale = {
  name: "custom_se",
  options: {
    months: [
      "Januari",
      "Februari",
      "Mars",
      "April",
      "Maj",
      "Juni",
      "Juli",
      "Augusti",
      "September",
      "Oktober",
      "November",
      "December",
    ],
    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Maj",
      "Juni",
      "Juli",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ],
    days: [
      "Söndag",
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag",
    ],
    shortDays: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
    toolbar: {
      exportToSVG: "Ladda ner SVG",
      exportToPNG: "Ladda ner PNG",
      exportToCSV: "Ladda ner CSV",
      menu: "Meny",
      selection: "Selektion",
      selectionZoom: "Val av zoom",
      zoomIn: "Zooma in",
      zoomOut: "Zooma ut",
      pan: "Panorering",
      reset: "Återställ zoomning",
    },
  },
};

const defaultChartOptions: ApexOptions = {
  chart: {
    stacked: false,
    fontFamily: "Manrope, sans-serif",
    locales: [defaultChartLocale],
    defaultLocale: "custom_se",
    toolbar: {
      show: true,
      tools: {
        download: '<i class="fa fa-download"></i>',
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false,
      },
      export: {
        csv: {
          columnDelimiter: ";",
          dateFormatter(timestamp) {
            return new Date(timestamp).toDateString();
          },
        },
        svg: {
          filename: undefined,
        },
        png: {
          filename: undefined,
        },
      },
    },
  },
  title: {
    text: "",
    style: {
      color: "#00838b",
      fontSize: "24px",
    },
  },
  subtitle: {
    text: "",
    align: "left",
    offsetX: 0,
    offsetY: 30,
    style: {
      color: "#9699a2",
      fontSize: "16px",
    },
  },
  dataLabels: {
    style: {
      colors: ["#2e2e2e"],
      fontSize: "14px",
    },
  },
  legend: {
    position: "bottom",
    fontSize: "14px",
  },
  colors: [
    "#fac228",
    "#f39200",
    "#146e71",
    "#1c98a2",
    "#60d1c8",
    "#a8dadc",
    "#f39200",
    "#ffe499",
    "#c5a67b",
    "#b15dd2",
    "#82b582",
    "#c2c3c4",
    "#ed7ee1",
    "#74db87",
    "#f25058",
  ],
  xaxis: {
    labels: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
      },
    },
  },
  yaxis: {
    labels: {
      minWidth: 0,
      maxWidth: 300,
    },
    title: {
      style: {
        fontSize: "14px",
        fontWeight: 600,
      },
    },
  },
  tooltip: {
    style: {
      fontSize: "16px",
    },
  },
};

export default defaultChartOptions;
