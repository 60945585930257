







































import { Vue, Component } from "vue-property-decorator";
// import { organisationModule } from "@/store/modules/organisation";
import OrganizationDetails from "../components/details/OrganizationDetails.vue";
import ActivityDetails from "../components/details/ActivityDetails.vue";

import ColumnChart from "../components/details/ColumnChart.vue";
import PieChart from "../components/details/PieChart.vue";
import FilterBar from "../components/shared/FilterBar.vue";

@Component({
  components: {
    OrganizationDetails,
    ActivityDetails,
    ColumnChart,
    FilterBar,
    PieChart,
  },
})
export default class StartView extends Vue {
  // get organisation() {
  //   return organisationModule;
  // }
}
