


















































































































import { Vue, Component } from "vue-property-decorator";
import { organisationModule } from "@/store/modules/organisation";
import VueApexCharts from "vue-apexcharts";
import Dropdown from "@/components/shared/Dropdown.vue";
import InfoIcon from "vue-material-design-icons/InformationOutline.vue";
import StackedBarChart from "@/components/energy/StackedBarChart.vue";
import {
  ChartSubTitle,
  EnergyDataUnit,
} from "@/components/energy/energyDataEnums";
import { BPopover } from "bootstrap-vue";
import { IEnergyFilter } from "@/models/models";

@Component({
  components: {
    apexchart: VueApexCharts,
    StackedBarChart,
    Dropdown,
    InfoIcon,
  },
})
export default class EnergyData extends Vue {
  public data = {};
  public isDataLoaded: boolean = false;

  public selection: IEnergyFilter = {
    year: null,
    energy: null,
    unit: EnergyDataUnit.MEGAWATT_HOURS,
  };

  public async mounted() {
    this.isDataLoaded = true;

    this.organisation.setEnergyFilter({
      year: null,
      energy: null,
      unit: EnergyDataUnit.MEGAWATT_HOURS,
    });
  }

  get organisation() {
    return organisationModule;
  }

  get energyFilter() {
    return organisationModule.energyFilter;
  }

  get yearOptions() {
    return [
      { value: null, text: "Alla år" },
      ...this.organisation.years
        .sort()
        .map((year) => ({ value: year, text: year.toString() })),
    ];
  }

  get energyOptions() {
    return [
      { value: null, text: "All energi" },
      ...Object.values(ChartSubTitle).map((energy) => ({
        value: energy,
        text: energy,
      })),
    ];
  }

  get unitOptions() {
    return [
      ...Object.values(EnergyDataUnit).map((energy) => ({
        value: energy,
        text: energy,
      })),
    ];
  }

  get chartSubTitle() {
    return ChartSubTitle;
  }

  selectYear(year: number | null) {
    this.organisation.setEnergyFilter({ ...this.energyFilter, year });
  }

  selectEnergy(energy: ChartSubTitle | null) {
    const setToMwh = !energy || energy !== ChartSubTitle.FUEL;
    const unit = setToMwh
      ? EnergyDataUnit.MEGAWATT_HOURS
      : this.energyFilter.unit;

    this.organisation.setEnergyFilter({ ...this.energyFilter, energy, unit });
  }

  selectUnit(unit: EnergyDataUnit) {
    this.organisation.setEnergyFilter({ ...this.energyFilter, unit });
  }
}
