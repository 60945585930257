import 'core-js/stable';
import Vue from 'vue';
import App from './App.vue';
import Message from './components/shared/Message.vue';
import Dropdown from './components/shared/Dropdown.vue';
import router from './router';
import '@/common/filters/number';
import '@/common/filters/unit';
import '@/common/filters/capitalize';
import 'core-js'; // Polyfills
import moment from 'moment';
import { Config } from './Configuration';
//import 'bootstrap';
import browserDetect from "vue-browser-detect-plugin";
import i18n from '@/common/utils/i18n/i18n';
import { store } from './store';
//import Fragment from 'vue-fragment';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'


moment.locale('sv')

//Vue.use(Fragment.Plugin);
Vue.use(browserDetect);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.component('message-component', Message);
Vue.component('dropdown-component', Dropdown);
Vue.config.productionTip = false;

if (document.getElementById('app')) {

    Config.loadSettings().then(() => {
        new Vue({
            router: router,
            store,
            i18n,
            render: h => h(App)
        }).$mount('#app');
    });
}