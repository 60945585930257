import axios from "axios";

class ApiService {
  // getGeneral(key: string): any {

  // 	return new Promise((resolve, reject) => {
  // 		axios.get('/organisations/' + key)
  // 			.then(response => {
  // 				resolve(response);
  // 			})
  // 			.catch(error => {
  // 				reject(error);
  // 			});
  // 	});
  // }

  // getData(key: string, currentYear: string, previousYear: string): any {
  // 	return new Promise((resolve, reject) => {
  // 		axios.get('/organisations/' + key + '/emissions', { params: { currentYear: currentYear, previousYear: previousYear } })
  // 			.then(response => {
  // 				resolve(response);
  // 			})
  // 			.catch(error => {
  // 				reject(error);
  // 			});
  // 	});
  // }

  public async getGeneralData(key: string): Promise<any> {
    const response = await axios.get(`/organisations/${key}`);
    return response.data;
  }

  public async getEmissionData(key: string, years: number[]): Promise<any> {
    const response = await axios.get(`/organisations/${key}/emissions`, {
      params: { years: years.join(",") },
    });
    return response.data;
  }

  public async getGeoEmissions(areaId: number): Promise<any> {
    const response = await axios.get(`/geodata/emissions/${areaId}`);
    return response.data;
  }
}

const apiService: ApiService = new ApiService();
export default apiService;
