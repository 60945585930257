













import { Vue, Component, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import defaultChartOptions from "@/models/defaultChartOptions";
import { organisationModule } from "@/store/modules/organisation";
import { ApexOptions } from "apexcharts";
import { ChartSubTitle, EnergyDataUnit } from "./energyDataEnums";
import { findByName } from "./colors";
import { IYearlyEnergyData } from "@/models/models";
import Helper from "@/utils/helper";
import Utils from "@/common/utils/utils";
import _ from "lodash";

@Component({
  components: {
    apexchart: VueApexCharts,
  },
})
export default class StackedBarChart extends Vue {
  @Prop({ default: false }) readonly shouldGetStatic!: boolean;

  public get organisation() {
    return organisationModule;
  }

  private get energyEmissions(): IYearlyEnergyData[] {
    return this.organisation.energyEmissions.map((x) => ({
      ...x,
      Year: x.Year.toString(),
      Mwh: x.Kwh / 1000,
    }));
  }

  private get year() {
    return this.organisation.energyFilter.year;
  }

  private get energy() {
    return this.organisation.energyFilter.energy;
  }

  private get unit() {
    return this.organisation.energyFilter.unit;
  }

  private get energyCarriers() {
    return _.uniq(this.energyEmissions.map((x) => x.Name));
  }

  private get years() {
    return _.uniq(this.energyEmissions.map((x) => x.Year));
  }

  get categories() {
    return this.year !== null ? this.energyCarriers : this.years;
  }

  private dataValue = (
    energyData: IYearlyEnergyData,
    force?: "fossil" | "renewable"
  ) => {
    if (this.energy === ChartSubTitle.FOSSIL || force === "fossil") {
      return energyData.Mwh * ((100 - energyData.RenewablePercentage) / 100);
    }

    if (this.energy === ChartSubTitle.RENEWABLE || force === "renewable") {
      return energyData.Mwh * (energyData.RenewablePercentage / 100);
    }

    if (this.unit === EnergyDataUnit.SWEDISH_CROWNS) {
      return energyData.Cost;
    }

    if (this.unit === EnergyDataUnit.LITER) {
      return energyData.AsLiter;
    }

    return energyData.Mwh;
  };

  get series(): ApexAxisChartSeries | ApexAxisChartSeries[] {
    if (this.year === null && this.energy !== null) {
      return this.energyCarriers.map((name) => {
        return {
          name,
          data: this.categories.map((category) => {
            const match = this.energyEmissions.find(
              (x) => x.Name === name && x.Year === category
            );
            return match ? Helper.round(this.dataValue(match)) : 0;
          }),
        };
      });
    }

    if (this.year !== null) {
      return [
        {
          name: this.year.toString(),
          data: this.categories.map((category) => {
            const match = this.energyEmissions.find(
              (x) => x.Name === category && x.Year === this.year.toString()
            );
            return match ? Helper.round(this.dataValue(match)) : 0;
          }),
        },
      ];
    }

    return [
      {
        name: ChartSubTitle.FOSSIL,
        data: this.categories.map((category) =>
          Helper.round(
            this.energyEmissions
              .filter((x) => x.Year === category && x.RenewablePercentage < 100)
              .reduce((acc, curr) => acc + this.dataValue(curr, "fossil"), 0)
          )
        ),
      },
      {
        name: ChartSubTitle.RENEWABLE,
        data: this.categories.map((category) =>
          Helper.round(
            this.energyEmissions
              .filter((x) => x.Year === category && x.RenewablePercentage > 0)
              .reduce((acc, curr) => acc + this.dataValue(curr, "renewable"), 0)
          )
        ),
      },
    ];
  }

  private yearlyPercentage = (year: string, amount: number) => {
    const filtered = this.energyEmissions.filter((x) => x.Year === year);

    if (this.unit === EnergyDataUnit.SWEDISH_CROWNS) {
      return Helper.round(
        (amount / filtered.reduce((sum, curr) => sum + curr.Cost, 0)) * 100
      );
    }

    if (this.unit === EnergyDataUnit.LITER) {
      return Helper.round(
        (amount / filtered.reduce((sum, curr) => sum + curr.AsLiter, 0)) * 100
      );
    }

    return Helper.round(
      (amount / filtered.reduce((sum, curr) => sum + curr.Mwh, 0)) * 100
    );
  };

  get colors(): string[] {
    if (this.energy !== null && this.year === null) {
      return this.energyCarriers.map((name) => {
        const match = findByName(name);
        return match
          ? match.color
          : Math.floor(Math.random() * 16777215).toString(16);
      });
    }

    if (this.year !== null) {
      return this.categories.map((category) => {
        const match = findByName(category);
        return match
          ? match.color
          : Math.floor(Math.random() * 16777215).toString(16);
      });
    }

    return ["#1c98a2", "#f39200"];
  }

  get options(): ApexOptions {
    return _.merge({}, defaultChartOptions, {
      chart: {
        stacked: !this.year,
      },
      responsive: [
        {
          breakpoint: 400,
          options: {
            chart: {
              height: "300px",
            },
          },
        },
      ],
      legend: {
        show: !this.year,
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number, opts: any) => {
          return Utils.localeFormatter(val);
        },
      },
      colors: this.colors,
      xaxis: {
        categories: this.categories,
        title: { text: !this.year ? "" : "(Energibärare)" },
      },
      yaxis: {
        title: { text: "(" + this.unit + ")" },
        labels: {
          formatter: (val: number) => {
            return Utils.localeFormatter(val);
          },
        },
      },
      title: {
        text: "Energianvändning",
      },
      subtitle: {
        text: this.energy
          ? this.energy + (this.year ? " - " + this.year : "")
          : "",
      },
      plotOptions: {
        bar: {
          distributed: this.year !== null,
          dataLabels: {
            total: {
              enabled: true,
              offsetY: -10,
            },
          },
        },
      },
      states: {
        hover: {
          filter: {
            type: "darken",
            value: 0.75,
          },
        },
        active: {
          filter: {
            type: "none",
          },
        },
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const year = !this.year
            ? w.globals.labels[dataPointIndex]
            : w.globals.seriesNames[seriesIndex];

          const name = !this.year
            ? w.globals.seriesNames[seriesIndex]
            : w.globals.labels[dataPointIndex];

          const value = series[seriesIndex][dataPointIndex];

          const color = !this.year
            ? w.config.colors[seriesIndex]
            : w.config.colors[dataPointIndex];

          const match = this.energyEmissions.find(
            (x) => x.Name === name && x.Year === year
          );

          const renewablePercentage = match && match.RenewablePercentage;
          const fossilPercentage = match && 100 - match.RenewablePercentage;

          const yearlyPercentage = this.yearlyPercentage(year, value);

          const valueAndUnit = `${Utils.localeFormatter(value, 2)} ${
            this.unit
          }`;

          const header = `<p class="font-weight-bold">${year} - ${name}</p>`;
          const amount = `<p><span class="font-weight-bold">Mängd:</span> ${valueAndUnit}</p>`;
          const percentage = `<p><span class="font-weight-bold">Procentuellt för året:</span> ${yearlyPercentage} %</p>`;

          const firstInfoPercentage =
            match &&
            Helper.round(
              this.energy === ChartSubTitle.RENEWABLE
                ? renewablePercentage
                : fossilPercentage
            );

          const renewableOrFossil =
            this.energy === ChartSubTitle.RENEWABLE ? "förnybar" : "fossilt";

          const secondInfoPercentage =
            match &&
            Helper.round(
              this.energy === ChartSubTitle.RENEWABLE
                ? fossilPercentage
                : renewablePercentage
            );

          let information = "";

          if (this.energy !== null && this.energy !== ChartSubTitle.FUEL) {
            information += `<p class="info"><span class="font-weight-bold">Information: </span>${name} `;
            if (
              this.energy === ChartSubTitle.RENEWABLE ||
              this.energy === ChartSubTitle.FOSSIL
            ) {
              information += `är till ${firstInfoPercentage}% ${renewableOrFossil}. Därav har resterande ${secondInfoPercentage}% exkluderats från denna tabell.`;
            } else {
              information +=
                "Den andel av den valda energibäraren som är förnybar/fossil är inte tillgänglig just nu.";
            }

            information += "</p>";
          }

          return (
            `<div class="custom-tooltip" style="padding:0.5rem; border-left: ${color} solid 1rem">` +
            header +
            amount +
            percentage +
            information +
            "</div>"
          );
        },
      },
    } as ApexOptions);
  }
}
